(function () {
    const loadOvl = (containerClass) => {
        jQuery.ajax({
            url: REGISTER_OVL_URI,
            jsonp: "callback",
            dataType: "jsonp",
            data: {withSocialConnect: 1},
            success: function( response ) {
                jQuery(".registerContainerLoading").removeClass('registerContainerLoading');
                jQuery(containerClass).html(response);
            }
        });
    };

    const documentWidth = jQuery(document).width();
    const modal = jQuery('#registerModal');
    const registerContainer =  jQuery("#registerContainer");

    jQuery(document).on('click', '.btn-register-modal', (e) => {
        modal.modal('show');
        e.preventDefault();
   });

    modal.on('show.bs.modal', function () {
        if (documentWidth > 767) {
            registerContainer.addClass('registerContainerBg');
            jQuery("#registerContainer > div").prependTo("#registerModalBody");
        } else {
            loadOvl('#registerModalBody');
        }
    });

    modal.on('hide.bs.modal', function () {
        if (documentWidth > 767) {
            registerContainer.removeClass('registerContainerBg');
            jQuery("#registerModalBody > div").prependTo("#registerContainer");
        }
    });

    if(documentWidth > 767) {
        loadOvl('#registerContainer');
    }
})();